<template>
  <div class="content-container">
    <div class="center-container">
      <p class="title">行为记录单</p>
      <table class="table">
        <tr>
          <th>时间</th>
          <th>行为1</th>
          <th>行为时间</th>
          <th>行为2</th>
          <th>行为时间</th>
          <th>行为3</th>
          <th>行为时间</th>
          <th>行为4</th>
          <th>行为时间</th>
        </tr>
        <tr v-for="(value, key) in result" :key="key">
            <td>{{key}}</td>
            <template v-for="i in 4">
                <td :key="'b' + i">{{value[i - 1] ? value[i - 1]['content'] : ''}}</td>
                <td :key="'t' + i">{{value[i - 1] ? value[i - 1]['time'] : ''}}</td>
            </template>
        </tr>
      </table>
    </div>
    <div class="symbol-box tac">
      <Button type="primary" size="large" @click="$router.back()">返回</Button>
    </div>
  </div>
</template>

<script>
import MemberService from '@/services/memberService';

export default {
	data() {
		return {
			result: [],
		};
	},
	created() {
		this.getData();
	},
	methods: {
		getData() {
			MemberService.getECGData(this.$route.params.record_id).then((res) => {
				const data = JSON.parse(res.nr);
				this.result = data;
			});
		},
	},
};
</script>

<style lang="less" scoped>
.content-container {
  background-color: #fff;
  padding: 15px;
  margin-top: 10px;
  .center-container {
    width: 1000px;
    margin: 0 auto;
  }
  .title {
    text-align: center;
    margin: 10px 0;
    font-size: 22px;
  }
  .table {
    width: 100%;
    th, td {
      border: 1px solid;
      padding: 5px 10px;
      text-align: center;
    }
  }
  .symbol-box {
    margin-top: 20px;
  }
}
</style>
